import moment from 'moment';

export class DateUtil {
  // Date -> YYYYMMDD
  static getYYYY_MM_DD_WEEK(date: Date | null) {
    if (date) {
      const week = moment(date).locale('ko').format('dddd').substr(0, 1);
      return `${moment(date).format('YYYY-MM-DD')}(${week})`;
    } else {
      return '';
    }
  }

  // Date -> YYYYMMDD
  static getYYYYMMDD(date: Date | null) {
    if (date) {
      return moment(date).format('YYYYMMDD');
    } else {
      return '';
    }
  }
  static getYYYY_MM_DD(date: Date | null) {
    if (date) {
      return moment(date).format('YYYY-MM-DD');
    } else {
      return '';
    }
  }

  // Date -> YYYY
  static getYearString(date: Date | null) {
    if (date) {
      return '' + date.getFullYear();
    } else {
      return '';
    }
  }

  // Date -> MM
  static getMonthString(date: Date | null) {
    if (date) {
      const mm = date.getMonth() + 1; // getMonth() is zero-based
      return (mm > 9 ? '' : '0') + mm;
    } else {
      return '';
    }
  }

  // Date -> DD
  static getDayString(date: Date | null) {
    if (date) {
      const dd = date.getDate();
      return (dd > 9 ? '' : '0') + dd;
    } else {
      return '';
    }
  }
}
