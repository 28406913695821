<template>
  <div class="top">
    <div class="header">
      <div class="headerimage" />
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center">
        <!--        <div>-->
        <!--          &lt;!&ndash;          <div class="overlay"><h1>SPECIAL BENEFITS</h1></div>&ndash;&gt;-->
        <!--          <img src="@/assets/images/pleisure/pleisure_headerimage.jpg" />-->
        <!--        </div>-->
        <div class="text-center col-lg-12 mt-5 mb-4">
          <h2><strong>P-Leisure 예약신청</strong></h2>
        </div>
        <div class="col-lg-12 text-center mb-3" style="margin-bottom: 30px">
          <h4>&lt; 예약 전 필수 공지사항 &gt;</h4>
        </div>
      </div>

      <div class="row d-flex justify-content-center p-3">
        <p>
          - 본 페이지는 P-Leisure(핀크스 레저) 예약신청 페이지입니다.<br />
          - 기재해주신 정보를 토대로 예약 내용 확인 후 확정 안내 드립니다.<br />
          - 신청 접수 후에는 예약 확정을 위하여 기재해주신 연락처로 전화 회신 드리오니, 반드시 연락 가능한 전화번호로
          기재 바랍니다.<br />
          - 모든 예약은 7일 전 예약을 원칙으로 합니다.<br />
          ※ 프로그램 이용료는 5세 이상부터 적용 됩니다.<br />
          ※ 예약문의 및 상담 : 064-793-7070, 010-5328-7050, pinxleisure@sk.com
        </p>
      </div>

      <div class="row d-flex justify-content-center">
        <div class="col-lg-6 col-12 mt-5 mb-5">
          <p style="font-size: 1.1rem">
            <strong>[{{ pData[$route.params.idx].name }}] 예약 고객 정보 입력(필수)</strong>
          </p>
          <table class="reserv-info table table-borderless">
            <tbody>
              <tr>
                <td>· 성명</td>
                <td>
                  <FormRequiredInput v-model="userName"></FormRequiredInput>
                </td>
              </tr>
              <!--              <tr>-->
              <!--                <td>· 생년월일</td>-->
              <!--                <td>-->
              <!--                  <b-form-input autocomplete="off" v-model="userBirth" placeholder="ed) 19990101"></b-form-input>-->
              <!--                </td>-->
              <!--              </tr>-->
              <tr>
                <td>· 휴대폰</td>
                <td>
                  <FormTel autocomplete="off" v-model="userPhone" placeholder="휴대폰 번호"></FormTel>
                </td>
              </tr>
              <tr>
                <td>· 이메일</td>
                <td>
                  <FormRequiredInput v-model="userEmail"></FormRequiredInput>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <p style="font-size: 1.1rem">
            <strong>[{{ pData[$route.params.idx].name }}] 프로그램 신청정보 입력</strong>
          </p>
          <p>
            ※ 희망일자, 인원수, 메인, 추가옵션은 필수 선택사항 입니다.<br />
            ※ 세부요청사항 : 별도 희망일자, 영유아동반여부(인원, 연령), 보호자 인원<br />&nbsp;&nbsp;&nbsp;&nbsp;(키즈승마
            한정) 등 기타요청사항
          </p>

          <!--          <b-form-textarea></b-form-textarea>-->
          <table class="reserv-info table table-borderless">
            <tbody>
              <tr>
                <td>· 희망일자</td>
                <td>
                  <div class="d-flex">
                    <v-date-picker v-model="myDate" :masks="v_datepicker_masks">
                      <template v-slot="{inputValue, inputEvents}">
                        <b-form-input autocomplete="off" :value="inputValue" v-on="inputEvents" />
                      </template>
                    </v-date-picker>
                    <b-form-select v-model="selectedAmPm" :options="myDateAmPm"></b-form-select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>· 인원수</td>
                <td>
                  <b-form-select v-model="selectedMan" :options="pData[$route.params.idx].man"></b-form-select>
                </td>
              </tr>
              <tr>
                <td>· 메인</td>
                <td>
                  <b-form-select v-model="selectedOptions1" :options="pData[$route.params.idx].options1">
                  </b-form-select>
                </td>
              </tr>
              <tr>
                <td>· 추가옵션</td>
                <td>
                  <b-form-select v-model="selectedOptions2" :options="pData[$route.params.idx].options2">
                  </b-form-select>
                </td>
              </tr>
              <tr>
                <td>· 세부요청사항</td>
                <td>
                  <b-form-textarea
                    v-model="inputDescription"
                    placeholder="별도희망일자, 영유아 동반 여부"
                    rows="3"
                    max-rows="6"
                  >
                  </b-form-textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-lg-6 col-12">
          <div class="graybox">
            <p>※ 취소 및 위약금 규정</p>
            <p>
              - 단순 고객변심에 의한 취소/환불은 업체 위약 규정에 따라<br />
              &nbsp;&nbsp;적용됩니다. (프로그램 별 페이지에서 확인 가능)<br />
              - 천재지변 및 업체의 운영상 부득이한 상황으로 취소 시,<br />
              &nbsp;&nbsp;위약 규정이 적용되지 않습니다.
            </p>
            <div class="section-agree">
              <table class="table table-borderless">
                <tbody>
                  <tr class="btn-agree">
                    <td>
                      <b-form-checkbox v-model="agree1">(전체 동의) 개인정보 수집 및 활용 동의</b-form-checkbox>
                    </td>
                    <td style="text-align: right">
                      <button class="btnStyle" @click="isShow = !isShow">전체보기</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TransitionCollapseHeight v-show="isShow">
              <div>
                <div>
                  <h5 class="mt-5 text-center">에스케이핀크스(주) 개인정보 수집‧이용 동의서</h5>
                  <p>
                    에스케이핀크스(주)는 핀크스 레저(이하 “프로그램”이라 함)의 예약 및 상담을 위하여 아래와 같이
                    개인정보를 수집 및 이용하고자 합니다. 귀하는 동의를 거부할 권리가 있으며, 필요한 최소한의 개인정보
                    수집이 불가능할 경우에는 프로그램의 예약 및 상담이 어려울 수 있음을 알려 드립니다.
                  </p>
                  <p style="color: #757575">□ 개인정보 수집‧이용 내역(필수사항)</p>
                  <table class="agree-info table table-borderless">
                    <tbody style="text-align: center">
                      <tr
                        style="
                          border-top: 3px solid #252525;
                          border-bottom: 1px solid #cfcfcf;
                          background-color: #f3f3f3;
                        "
                      >
                        <td style="border-right: 1px solid #cfcfcf">필수항목</td>
                        <td style="border-right: 1px solid #cfcfcf">수집목적</td>
                        <td>보유기간</td>
                      </tr>
                      <tr style="border-bottom: 1px solid; background-color: #ffffff">
                        <td style="border-right: 1px solid #cfcfcf">
                          <small>예약자 성함, 생년월일, 이메일, 연락처</small>
                        </td>
                        <td style="border-right: 1px solid #cfcfcf">
                          <h5><strong>프로그램 예약 및 상담</strong></h5>
                        </td>
                        <td>
                          <h5><strong>업무처리 후 즉시 파기</strong></h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    ※ 위의 개인정보 수집‧이용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 프로그램
                    예약 및 상담에 제한을 받을 수 있습니다.
                  </p>
                  <div class="text-right mb-4">
                    <b-form-checkbox v-model="agree2">개인정보 수집 및 이용에 동의합니다.</b-form-checkbox>
                  </div>
                  <p style="color: #757575">□ 개인정보 처리 위탁(필수사항)</p>
                  <table class="agree-info table table-borderless">
                    <tbody style="text-align: center">
                      <tr style="border-top: 3px solid; border-bottom: 1px solid #cfcfcf; background-color: #f3f3f3">
                        <td style="border-right: 1px solid #cfcfcf">수탁자</td>
                        <td>위탁업무 내용</td>
                      </tr>
                      <tr style="border-bottom: 1px solid #cfcfcf">
                        <td style="border-right: 1px solid #cfcfcf; background-color: #f3f3f3">SK C&C(주)</td>
                        <td style="background-color: #ffffff">서버 네트워킹 서비스</td>
                      </tr>
                      <tr style="border-bottom: 1px solid #cfcfcf">
                        <td style="border-right: 1px solid #cfcfcf; background-color: #f3f3f3">SK브로드밴드(주)</td>
                        <td style="background-color: #ffffff">문자발송(SMS) 서비스</td>
                      </tr>
                      <tr style="border-bottom: 1px solid #cfcfcf">
                        <td style="border-right: 1px solid #cfcfcf; background-color: #f3f3f3">SCI 평가정보(주)</td>
                        <td style="background-color: #ffffff">아이핀/휴대폰을 통한 본인인증</td>
                      </tr>
                      <tr style="border-bottom: 1px solid">
                        <td style="border-right: 1px solid #cfcfcf; background-color: #f3f3f3">SK 네트웍스서비스㈜</td>
                        <td style="background-color: #ffffff">
                          홈페이지 운영 관리, 논리적 및 물리적 데이터 베이스 관리
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    ※ 위의 개인정보 처리 위탁에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 프로그램
                    예약 및 상담에 제한을 받을 수 있습니다.
                  </p>
                  <div class="text-right">
                    <b-form-checkbox v-model="agree3">개인정보 처리 위탁에 동의합니다.</b-form-checkbox>
                  </div>
                  <hr />
                  <p>
                    "SK핀크스(주)"는 "개인정보보호법" 및 "정보통신망 이용 촉진 및 정보보호 등에 관한 법률"을 준수하며
                    상기 명시된 이외의 개인정보보호 관련 사항은 "SK핀크스"의 개인정보 처리방침에 준하며 자세한 내용은
                    www.thepinx.co.kr에서 확인하실 수 있습니다.
                  </p>
                </div>
              </div>
            </TransitionCollapseHeight>
          </div>

          <button class="box-agree-confirm" @click="confirm">예약신청하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionCollapseHeight from '@/components/TransitionCollapseHeight';
import {DateUtil} from '@/util/DateUtil';
import axios from 'axios';
import FormTel from '@/components/forms/FormTel';
import FormRequiredInput from '@/components/forms/FormRequiredInput';

export default {
  name: 'reservpleisure',
  components: {
    TransitionCollapseHeight,
    FormTel,
    FormRequiredInput,
  },
  data() {
    return {
      selectedAmPm: '오전',
      myDateAmPm: [
        {value: '오전', text: '오전'},
        {value: '오후', text: '오후'},
      ],
      v_datepicker_masks: {
        input: 'YYYY-MM-DD',
      },
      isShow: false,
      agree1: false,
      agree2: false,
      agree3: false,
      userName: '',
      //userBirth: '',
      userPhone: '',
      userEmail: '',
      selectedMan: null,
      selectedOptions1: null,
      selectedOptions2: null,
      inputDescription: '',
      myDate: new Date(),
      // 0 : 럭셔리 요트
      pData: [
        {
          name: '프라이빗 럭셔리요트',
          man: ['1명', '2명', '3명', '4명', '5명', '6명', '7명', '8명', '9명', '10명'],
          options1: ['오션투어', '송악산 투어', '차귀도 투어'],
          options2: [
            '없음',
            '수테라피, 사격, 오모로비짜 스파(40분)',
            '메디컬 스파(바디)',
            '오모로비짜 스파(90분)',
            '스냅(요트)',
            '플라워 세팅',
          ],
        },
        {
          name: '챌린저골프',
          man: ['2명', '3명'],
          options1: ['원포인트+필드레슨', '필드레슨'],
          options2: [
            '없음',
            '수테라피, 사격, 오모로비짜 스파(40분)',
            '메디컬 스파(바디)',
            '오모로비짜 스파(90분)',
            '스냅(요트)',
            '플라워 세팅',
          ],
        },
        /*{
          name: '[7~8월 한정]챌린저 골프',
          man: ['2명', '3명'],
          options1: ['원포인트+필드레슨', '필드레슨'],
          options2: [
            '없음',
            '그린사이드BBQ Special Course',
            '수테라피, 사격, 오모로비짜 스파(40분)',
            '메디컬 스파(바디)',
            '오모로비짜 스파(90분)',
            '스냅(요트)',
            '플라워 세팅',
          ],
        },*/
        {
          name: '노블승마',
          man: ['1명', '2명', '3명', '4명', '5명', '6명'],
          options1: ['실내&실외 승마', '실내승마'],
          options2: [
            '없음',
            '수테라피, 사격, 오모로비짜 스파(40분)',
            '메디컬 스파(바디)',
            '오모로비짜 스파(90분)',
            '플라워 세팅',
          ],
        },
        {
          name: '노블승마(키즈)',
          man: ['1명', '2명'],
          options1: ['실내&실외 승마', '실내승마'],
          options2: [
            '없음',
            '수테라피, 사격, 오모로비짜 스파(40분)',
            '메디컬 스파(바디)',
            '오모로비짜 스파(90분)',
            '플라워 세팅',
          ],
        },
        {
          name: '제주어부',
          man: ['1명', '2명', '3명', '4명', '5명', '6명', '7명', '8명', '9명', '10명'],
          options1: ['뱅에돔 낚시', '제철낚시'],
          options2: [
            '없음',
            '수테라피, 사격, 오모로비짜 스파(40분)',
            '메디컬 스파(바디)',
            '오모로비짜 스파(90분)',
            '플라워 세팅',
          ],
        },
        {
          name: '해피투게더',
          man: ['1명', '2명', '3명', '4명', '5명', '6명'],
          options1: ['곶자왈 투어+족욕'],
          options2: [
            '없음',
            '수테라피, 사격, 오모로비짜 스파(40분)',
            '메디컬 스파(바디)',
            '오모로비짜 스파(90분)',
            '플라워 세팅',
          ],
        },
        {
          name: '제주 숲토리텔링 투어',
          man: ['1명', '2명', '3명', '4명', '5명', '6명', '7명', '8명', '9명', '10명'],
          options1: ['돌오름 Up/Down 코스', '시크릿 숲 속 트레킹', '제주 산바다 트레킹', '프라이빗 한라산 트레킹'],
          options2: [
            '없음',
            '수테라피, 사격, 오모로비짜 스파(40분)',
            '메디컬 스파(바디)',
            '오모로비짜 스파(90분)',
            '플라워 세팅',
          ],
        },
        {
          name: '[7~9월 한정]스페셜 요트 스노클링',
          man: ['2명', '3명', '4명'],
          options1: ['프리이빗 요트 투어 + 스노클링(90분)'],
          options2: [
            '없음',
            '수테라피, 사격, 오모로비짜 스파(40분)',
            '메디컬 스파(바디)',
            '오모로비짜 스파(90분)',
            '스냅(요트)',
            '플라워 세팅',
          ],
        },
        {
          name: '그린사이드BBQ & 힐링',
          man: ['2명', '3명', '4명'],
          options1: ['그린사이드BBQ 특선코스 + 와인 1BT + 뷰티스파(90분)'],
          options2: ['없음'],
        },
      ],
    };
  },
  watch: {
    agree1(newVal, oldVal) {
      console.log(newVal, oldVal);
      if (newVal) {
        this.agree2 = true;
        this.agree3 = true;
      } else {
        this.agree2 = false;
        this.agree3 = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '예약신청'});
  },
  methods: {
    async confirm() {
      if (
        this.agree2 &&
        this.agree3 &&
        !this.isEmptyString(this.userName) &&
        //!this.isEmptyString(this.userBirth) &&
        !this.isEmptyString(this.userPhone) &&
        !this.isEmptyString(this.userEmail) &&
        !this.isEmptyString(this.selectedMan) &&
        !this.isEmptyString(this.selectedOptions1) &&
        !this.isEmptyString(this.selectedOptions2)
      ) {
        let message =
          `P-LEISURE 웹 주문건\n` +
          `상품명 : ${this.pData[this.$route.params.idx].name} \n` +
          `성명 : ${this.userName} \n` +
          //`생년월일 : ${this.userBirth} \n` +
          `휴대폰 : ${this.userPhone} \n` +
          `이메일 : ${this.userEmail} \n` +
          `인원수 : ${this.selectedMan} \n` +
          `메인옵션 : ${this.selectedOptions1} \n` +
          `추가옵션 : ${this.selectedOptions1} \n` +
          `희망일자 : ${this.myDate} ${this.selectedAmPm}\n` +
          `기타요청사항 : ${this.inputDescription} \n`;

        console.log(message);
        let firstDate = DateUtil.getYYYY_MM_DD(this.myDate);
        const wantDate = `${firstDate} ${this.selectedAmPm}`;
        const pleisureDto = {
          productName: this.replaceEscpeStr(this.pData[this.$route.params.idx].name),
          userName: this.replaceEscpeStr(this.userName),
          userPhone: this.replaceEscpeStr(this.userPhone),
          userEmail: this.replaceEscpeStr(this.userEmail),
          selectedMan: this.replaceEscpeStr(this.selectedMan),
          selectedOptions1: this.replaceEscpeStr(this.selectedOptions1),
          selectedOptions2: this.replaceEscpeStr(this.selectedOptions2),
          wantDate: wantDate,
          inputDescription: this.replaceEscpeStr(this.inputDescription),
        };
        const url = `/api/SendPleisureMail`;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        await axios
          .post(url, pleisureDto)
          .then(async response => {
            await self.$bvModal.msgBoxOk('예약신청이 접수되었습니다', {
              title: '성공',
              okVariant: 'success',
              centered: true,
            });
            self.$router.go(-1);
          })
          .catch(function (error) {
            if (error.response.status === 403) {
              self.$bvModal.msgBoxOk('스크립트 및 html을 입력할수 없습니다', {
                title: '입력오류',
                okVariant: 'danger',
                okTitle: '확인',
                centered: true,
              });
              return;
            }
            self.$bvModal.msgBoxOk('예약신청실패', {
              title: '오류발생',
              okVariant: 'danger',
              centered: true,
            });
          });
      } else {
        if (!this.agree2) {
          alert('개인정보 수집 및 이용 동의가 필요합니다.');
          return;
        }

        if (!this.agree3) {
          alert('개인정보 처리 위탁 동의가 필요합니다.');
          return;
        }

        if (this.isEmptyString(this.userName)) {
          alert('성명을 입력하세요.');
          return;
        }

        // if (this.isEmptyString(this.userBirth)) {
        //   alert('생년월일을 입력하세요.');
        //   return;
        // }

        if (this.isEmptyString(this.userPhone)) {
          alert('휴대폰 정보를 입력하세요.');
          return;
        }

        if (this.isEmptyString(this.userEmail)) {
          alert('이메일 정보를 입력하세요.');
          return;
        }

        if (this.isEmptyString(this.selectedMan)) {
          alert('인원수를 선택하세요.');
          return;
        }

        if (this.isEmptyString(this.selectedOptions1)) {
          alert('메인옵션을 선택하세요.');
          return;
        }

        if (this.isEmptyString(this.selectedOptions2)) {
          alert('추가옵션을 선택하세요.');
          return;
        }
      }
    },

    isEmptyString(value) {
      if (
        value == '' ||
        value == null ||
        value == undefined ||
        (value != null && typeof value == 'object' && !Object.keys(value).length)
      ) {
        return true;
      } else {
        return false;
      }
    },
    replaceEscpeStr(str) {
      let replaceStr = str.replace('&', '/');
      return replaceStr;
    },
    removeEventSpaces(e) {
      e.preventDefault();
      const left = e.target.value.substring(0, e.target.selectionStart);
      const right = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
      const pasted = (e.dataTransfer || e.clipboardData).getData('text').replace(/ /g, '');
      e.target.value = left + pasted + right;
    },
  },
};
</script>

<style scoped>
.reserv-info td {
  padding-top: 0;
  vertical-align: middle;
}

.graybox {
  background-color: #f0f0f0;
  padding: 20px;
}

.btn-agree {
  position: relative;
  text-align: left;
  background-color: #333333;
  color: #fff;
  padding: 14px 22px;
  height: 50px;
}

.section-agree {
  word-break: normal;
}

.agree-info h5 {
  margin: 0;
}

.agree-info td {
  vertical-align: middle;
}

.box-agree-confirm {
  position: relative;
  /*padding: 35px 217px 35px 217px;*/
  height: 90px;
  width: 100%;
  color: white;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  line-height: 90px;
  background-color: #333333;
}

.btn-agree-confirm {
  font-size: 17px;
}

.b-black {
  color: #fff;
}

.top {
  padding-top: 11rem;
}

.btnStyle {
  border: none;
  background-color: transparent;
  color: white;
}

.headerimage {
  width: 100%;
  margin: 0;
  content: url('~@/assets/images/pleisure/pleisure_headerimage.jpg');
}

@media (max-width: 992px) {
  .top {
    padding-top: 5rem;
  }

  .headerimage {
    display: none;
  }
}
</style>
